import React, { FC } from 'react';

import IconClipboard from '../../../icons/Clipboard';
import IconListAlt from '../../../icons/ListAlt';
import IconReceipt from '../../../icons/Receipt';
import { NavigationProps } from './index';
import NavGroup from './NavGroup';

const gestionMenu = (espaceId?: string) => [
  [
    {
      name: 'Paiements',
      to: `/espaces/${espaceId}/gestion/paiements/`,
    },
    {
      name: 'Réservations',
      to: `/espaces/${espaceId}/gestion/reservations/`,
    },
    // {
    //   name: 'Abonnements',
    //   to: `/espaces/${espaceId}/gestion/abonnements/`,
    // },
  ],
];

const NavigationCommerce: FC<NavigationProps> = ({
  className,
  docId,
  user,
}) => {
  if (
    'admin' !== user?.type &&
    (!user || !user.profils || !user.profils.includes('commerce'))
  ) {
    return null;
  }

  return (
    <NavGroup
      className={className}
      items={[
        {
          acl: {
            admin: true,
          },
          className: 'separator',
          iconLeft: IconClipboard,
          id: 'produits',
          name: 'Produits',
          path: `/espaces/${docId}/produits/`,
          profil: 'produits',
          to: `/espaces/${docId}/produits/`,
        },
        {
          acl: {
            admin: true,
          },
          iconLeft: IconListAlt,
          id: 'bons-de-commande',
          name: 'Bons de commande',
          path: `/espaces/${docId}/bons-commande/`,
          profil: 'commandes',
          to: `/espaces/${docId}/bons-commande/`,
        },
        {
          acl: {
            admin: true,
          },
          iconLeft: IconReceipt,
          id: 'gestion',
          menu: gestionMenu(docId),
          name: 'Gestion',
          path: `/espaces/${docId}/gestion/`,
          profil: 'gestion',
          to: `/espaces/${docId}/gestion/paiements/`,
        },
      ]}
      title="Commerce"
      user={user}
    />
  );
};

export default NavigationCommerce;
