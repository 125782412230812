import React, { FC } from 'react';

import IconCog from '../../../icons/Cog';
import { NavigationProps } from './index';
import MenuItem from './MenuItem';

const paramsMenu = (espaceId?: string) => [
  [
    {
      name: 'Caractéristiques',
      to: `/espaces/${espaceId}/params/features/`,
    },
    // {
    //   name: 'Catégories',
    //   to: `/espaces/${espaceId}/params/categories/`,
    // },
    {
      name: 'Livraisons',
      to: `/espaces/${espaceId}/params/livraisons/`,
    },
    {
      name: 'Tarifications',
      to: `/espaces/${espaceId}/params/tarifications/`,
    },
  ],
];

const NavigationFooter: FC<NavigationProps> = ({ docId, user }) => {
  if (
    'admin' !== user?.type &&
    (!user || !user.profils || !user.profils.includes('settings'))
  ) {
    return null;
  }

  return (
    <footer className="pb-3 border-t pt-1">
      <MenuItem
        item={{
          acl: {
            admin: true,
          },
          iconLeft: IconCog,
          id: 'parameters',
          menu: paramsMenu(docId),
          name: 'Paramètres',
          path: `/espaces/${docId}/params/`,
          profil: 'settings',
          to: `/espaces/${docId}/params/`,
        }}
        user={user}
      />
    </footer>
  );
};

export default NavigationFooter;
