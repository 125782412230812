import styled from '@emotion/styled';
import React, { FC, SyntheticEvent } from 'react';

import { screens } from '../../../styles/theme';
import Hamburger from './Hamburger';

const HeaderSC = styled.header`
  transition: width 0.1s cubic-bezier(0.21, 0.61, 0.35, 1);

  // background-color: #273a8a;
  background-color: #fff;
  @media (min-width: ${screens.md}) {
    flex-basis: 13rem;
  }
`;

const NavigationHeader: FC<{
  className?: string;
  openMenu: boolean;
  toggleMenu: (event: SyntheticEvent<HTMLElement>) => void;
}> = ({ className, openMenu, toggleMenu }) => (
  <HeaderSC className={className}>
    <Hamburger openMenu={openMenu} toggleMenu={toggleMenu} />
  </HeaderSC>
);

export default NavigationHeader;
