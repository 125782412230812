import styled from '@emotion/styled';
import { DocumentType, UserType } from '@innedit/innedit-type';
import React, { FC } from 'react';
import slug from 'slug';

import MenuProps from '../../../constants/menu';
import { ButtonProps } from '../../Button';
import { LayoutKindProps } from '../index';
import NavigationCanaux from './Canaux';
import NavigationCommerce from './Commerce';
import NavigationConnaissances from './Connaissances';
import NavigationEspaces from './Espaces';
import NavigationFooter from './Footer';
import MenuItem from './MenuItem';

export interface NavigationProps {
  docId?: string;
  className?: string;
  kind?: LayoutKindProps;
  menu?: MenuProps;
  user?: DocumentType<UserType>;
}

export interface SubMenuItemType {
  actionProps?: ButtonProps;
  name: string;
  path?: string;
  to: string;
}

export interface MenuItemType extends ButtonProps {
  acl?: {
    '*'?: boolean;
    admin?: boolean;
    beta?: boolean;
  };
  actionProps?: ButtonProps;
  menu?: SubMenuItemType[][];
  name: string;
  path?: string;
  profil: string;
  to: string;
}

const Nav = styled.nav`
  transition-duration: 200ms;
  transition-property: flex-basis, opacity, width, left;
  transition-timing-function: cubic-bezier(0.21, 0.61, 0.35, 1);

  flex-direction: column;

  z-index: 500;

  .container {
    flex: 1 1 100%;
    overflow: auto;
    overflow-x: hidden;
    padding-bottom: 1rem;
  }
`;

const Navigation: FC<NavigationProps> = ({
  className,
  docId,
  kind,
  menu,
  user,
}) => {
  const newMenu: MenuItemType[] | undefined = menu && menu(docId);

  return (
    <Nav className={`bg-background border-r ${className}`}>
      {'espace' === kind && (
        <NavigationEspaces className="mb-3" docId={docId} user={user} />
      )}
      <section className="container flex flex-col mt-1.5">
        {newMenu &&
          newMenu.map(item => (
            <MenuItem key={`menu_${slug(item.to)}`} item={item} user={user} />
          ))}

        {'espace' === kind && docId && (
          <>
            <NavigationCommerce className="mt-6" docId={docId} user={user} />
            <NavigationCanaux className="mt-6" docId={docId} user={user} />
            <NavigationConnaissances
              className="mt-6"
              docId={docId}
              user={user}
            />
          </>
        )}
      </section>
      {'espace' === kind && docId && (
        <NavigationFooter docId={docId} user={user} />
      )}
    </Nav>
  );
};

export default Navigation;
