import styled from '@emotion/styled';
import { useSelector } from '@innedit/formidable';
import { navigate } from 'gatsby';
import React, { FC, SyntheticEvent } from 'react';

import { ReducerCMSProps } from '../../../reducers/cms';
import { colors } from '../../../styles/theme';
import { NavigationProps } from './index';

export const Select = styled.div`
  position: relative;
  box-sizing: border-box;

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    border: none;
    background: none;
    position: relative;
    cursor: pointer;
    display: block;
    outline: none;
    font-weight: 300;
    border-bottom: 1px solid ${colors.neutral[100]};
    width: 100%;

    height: 35px;
    padding: 0 21px;
    box-sizing: border-box;
    line-height: 20px;
    color: ${colors.dark[500]};

    option {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      display: block;
    }
  }

  &:after {
    //@include arrow(#000);
    border: 1px solid ${colors.dark[500]};
    border-right: 0;
    border-top: 0;
    content: ' ';
    display: block;
    height: 0.5em;
    pointer-events: none;
    position: absolute;
    transform: rotate(-45deg);
    width: 0.5em;
    margin-top: -0.375em;
    right: 1.125em;
    top: 50%;
    z-index: 4;
  }
`;

const NavigationEspaces: FC<NavigationProps> = ({ className, docId }) => {
  const { espaces } = useSelector(
    (globalState: { cms: ReducerCMSProps }) => globalState.cms,
  );

  const handleOnChange = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget;

    return navigate(`/espaces/${value}/dashboard/`);
  };

  if (!espaces || 2 > espaces.length) {
    return null;
  }

  return (
    <Select className={className}>
      <select onChange={handleOnChange} value={docId}>
        {espaces.map(espace => (
          <option key={espace.id} value={espace.id}>
            {espace.name}
          </option>
        ))}
      </select>
    </Select>
  );
};

export default NavigationEspaces;
