import styled from '@emotion/styled';
import { useDispatch, useSelector } from '@innedit/formidable';
import classnames from 'classnames';
import { Link, navigate } from 'gatsby';
import compact from 'lodash/compact';
import { rgba } from 'polished';
import React, { FC, useEffect, useRef, useState } from 'react';

import { logout } from '../../../actions/auth';
import { ReducersProps } from '../../../reducers';
import { colors, screens, spacing } from '../../../styles/theme';
import Button from '../../Button';

const ULSC = styled.ul`
  min-width: ${spacing[40]};

  li {
    padding: 0.5rem 1rem;
    &:hover {
      background: ${rgba(colors.light[300], 0.1)};
    }
    border-radius: ${spacing[1]};
  }
`;

const SpanEL = styled.span`
  display: none;

  @media (min-width: ${screens.md}) {
    display: flex;
  }
`;

const ButtonSC = styled.button`
  padding: ${spacing[1]};

  @media (min-width: ${screens.md}) {
    padding: ${spacing[1]} ${spacing[3]} ${spacing[1]} ${spacing[3]};
  }
`;

const LayoutHeaderUser: FC<{ showConsole: boolean }> = ({ showConsole }) => {
  const dispatch = useDispatch();
  const [openUser, setOpenUser] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const { user } = useSelector((state: ReducersProps) => state.cms);

  const handleToggleUser = () => {
    setOpenUser(open => !open);
  };

  const handleOnClickLogout = async () => {
    dispatch(logout());
    await navigate('/');
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpenUser(false);
    }
  };

  if (!user) {
    return (
      <Button color="primary" to="/login/" variant="ghost">
        Se connecter
      </Button>
    );
  }

  const letters = user
    ? compact([user.firstName, user.lastName])
        .map((s: string) => s.charAt(0))
        .join('')
    : '';

  return (
    <div
      ref={ref}
      className={classnames(
        'relative flex content-center items-center pl-2 space-x-6',
        {
          'is-open': openUser,
        },
      )}
    >
      {showConsole &&
        user &&
        user.type &&
        user.espace &&
        ['owner', 'admin'].includes(user.type) && (
          <Button
            color="primary"
            to={`/espaces/${user.espace}/produits/`}
            variant="ghost"
          >
            Console
          </Button>
        )}
      <ButtonSC
        className={classnames('bg-light-500 rounded', {
          'bg-light-600': openUser,
        })}
        onClick={handleToggleUser}
        type="button"
      >
        <div className="flex items-center space-x-2 text-dark-400">
          <div
            className={classnames(
              'flex items-center justify-center rounded-full bg-quaternary-400 h-8 w-8 text-sm',
            )}
          >
            {letters}
          </div>
          <SpanEL>
            {user ? `${user.firstName} ${user.lastName}` : 'Utilisateur'}
          </SpanEL>
        </div>
      </ButtonSC>

      {openUser && (
        <div className="absolute bg-dark-500 text-light-500 p-2 rounded top-14 right-0.5">
          <ULSC>
            <li>
              <Link to="/edition/">Profil</Link>
            </li>
            {'admin' === user.type && (
              <li>
                <Link to="/admin/">Administration</Link>
              </li>
            )}
            {('admin' === user.type ||
              (user.espaces && user.espaces.length > 0)) && (
              <li>
                <Link to="/utilisateur/espaces/">Mes espaces</Link>
              </li>
            )}
            <li>
              <Link to="/utilisateur/abonnements/">Mes abonnements</Link>
            </li>
            <li>
              <Link to="/utilisateur/reservations/">Mes réservations</Link>
            </li>
            <li>
              <Link to="/utilisateur/abonnements/">Mes abonnements</Link>
            </li>
            <li>
              <Link to="/utilisateur/paiements/">Mes paiements</Link>
            </li>
            <li>
              <button onClick={handleOnClickLogout} type="button">
                Déconnexion
              </button>
            </li>
          </ULSC>
        </div>
      )}
    </div>
  );
};

export default LayoutHeaderUser;
