import styled from '@emotion/styled';
import { DocumentType, UserType } from '@innedit/innedit-type';
import classnames from 'classnames';
import objectHash from 'object-hash';
import React, { FC, useEffect, useState } from 'react';

import { spacing } from '../../../styles/theme';
import Button from '../../Button';
import { MenuItemType, SubMenuItemType } from './index';

const MenuItemEL = styled.div`
  & + & {
    margin-top: ${spacing.px};
  }
`;

const SubMenuItem: FC<{
  item: SubMenuItemType;
  pathname?: string;
}> = ({ item, pathname }) => (
  <li className="relative">
    <Button
      className={classnames({
        'is-active':
          pathname &&
          pathname.substring(0, (item.path || item.to).length) ===
            (item.path || item.to),
      })}
      size="sm"
      to={item.to}
      variant="sub-menu"
    >
      <span>{item.name}</span>
    </Button>
    {item.actionProps && <Button {...item.actionProps} size="sm" />}
  </li>
);

interface MenuItemProps {
  item: MenuItemType;
  user?: DocumentType<UserType>;
}

const MenuItem: FC<MenuItemProps> = ({
  item: { acl, actionProps, menu, name, path, profil, ...props },
  user,
}) => {
  const [topIsOpen, setTopIsOpen] = useState<boolean>(false);
  const [topIsExact, setTopIsExact] = useState<boolean>(false);
  const [pathname, setPathname] = useState<string>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setPathname(window && window.location.pathname);
    }
  });

  useEffect(() => {
    const p = path || props.to;

    if (window) {
      setTopIsOpen(window.location.pathname.substring(0, p.length) === p);
      setTopIsExact(window.location.pathname === p);
    }
  }, [path, pathname, props.to]);

  if (
    !acl ||
    0 ===
      Object.keys(acl).filter(data => '*' === data || data === user?.type)
        .length
  ) {
    if (!user || !user.profils || !user.profils.includes(profil)) {
      return null;
    }
  }

  return (
    <MenuItemEL className="relative">
      <Button
        {...props}
        className={classnames({
          'is-active':
            topIsExact || ((!menu || 0 === menu.length) && topIsOpen),
          'is-open': topIsOpen,
        })}
        size="sm"
        text={name}
        variant="menu"
      />
      {topIsOpen && menu && menu.length > 0 && (
        <div className="mt-0.5">
          {menu.map(list => (
            <ul key={objectHash(list)} className="flex flex-col space-y-0.5">
              {list.map(subitem => (
                <SubMenuItem
                  key={objectHash(subitem)}
                  item={subitem}
                  pathname={pathname}
                />
              ))}
            </ul>
          ))}
        </div>
      )}
      {actionProps && <Button {...actionProps} />}
    </MenuItemEL>
  );
};

export default MenuItem;
