import styled from '@emotion/styled';
import React, { FC, SyntheticEvent } from 'react';

import screens from '../../../../styles/screens';
import { colors } from '../../../../styles/theme';

const HamburgerSC = styled.a`
  position: relative;
  margin-top: 6px;
  height: 48px;
  width: 48px;
  display: none;

  @media only screen and (max-width: ${screens.md}) {
    display: block;
    position: absolute;
    left: 0;
  }

  .hamburger-top,
  .hamburger-bottom {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;

    transition: transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99),
      -webkit-transform 0.25s cubic-bezier(0.4, 0.01, 0.165, 0.99);
    transform: rotate(0);

    span {
      display: block;
      width: 18px;
      height: 2px;
      background: ${colors.dark[500]};
      position: absolute;
      left: 16px;
      z-index: 1;
      border-radius: 4px;
    }
  }

  .hamburger-top span {
    top: 23px;
    transform: translateY(-3px);
  }

  .hamburger-bottom span {
    bottom: 23px;
    transform: translateY(3px);
  }

  &.is-open {
    .hamburger-top {
      transform: rotate(45deg);
      span {
        transform: translateY(0px);
        top: 22px;
      }
    }

    .hamburger-bottom {
      transform: rotate(-45deg);

      span {
        transform: translateY(0px);
        bottom: 22px;
      }
    }
  }
`;

const Hamburger: FC<{
  openMenu: boolean;
  toggleMenu: (event: SyntheticEvent<HTMLElement>) => void;
}> = ({ openMenu, toggleMenu }) => (
  <HamburgerSC
    className={`hamburger ${openMenu ? 'is-open' : ''}`}
    onClick={toggleMenu}
  >
    <span className="hamburger-top">
      <span />
    </span>
    <span className="hamburger-bottom">
      <span />
    </span>
  </HamburgerSC>
);

export default Hamburger;
