import 'react-toastify/dist/ReactToastify.css';

import styled from '@emotion/styled';
import { DocumentType, UserType } from '@innedit/innedit-type';
import classnames from 'classnames';
import React, { FC, SyntheticEvent, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import MenuProps from '../../constants/menu';
import { screens } from '../../styles/theme';
import NavigationUser from './Header/User';
import Navigation from './Navigation';
import NavigationHeader from './Navigation/Header';

const Header = styled.div`
  position: relative;
`;

const MainHeader = styled.div`
  flex-basis: auto;
  // display: none;
  position: absolute;
  right: 0;
  display: flex;
  @media (min-width: ${screens.md}) {
    position: relative;
    right: auto;
  }
`;

const Main = styled.div`
  transition: margin-left cubic-bezier(0.21, 0.61, 0.35, 1) 200ms;
  position: relative;
`;

export type LayoutKindProps = 'admin' | 'espace' | 'user' | 'public';

interface LayoutProps {
  docId?: string;
  menu?: MenuProps;
  kind?: LayoutKindProps;
  showConsole?: boolean;
  showNavigation?: boolean;
  user?: DocumentType<UserType>;
}

const Layout: FC<LayoutProps> = ({
  children,
  docId,
  kind,
  menu,
  showConsole = false,
  showNavigation = true,
  user,
}) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false);

  const handleMenuToggle = (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();

    setOpenMenu(oM => !oM);
  };

  return (
    <>
      <ToastContainer />
      <Header className="z-50 flex justify-between w-full bg-light-50 fixed top-0 left-0 min-w-52">
        <NavigationHeader
          className="w-full md:w-52 flex justify-center h-14"
          openMenu={openMenu}
          toggleMenu={handleMenuToggle}
        />
        <MainHeader className="justify-end p-2">
          <NavigationUser showConsole={showConsole} />
        </MainHeader>
      </Header>
      {showNavigation && (
        <Navigation
          className={classnames(
            'fixed flex -left-52 md:left-0 w-52 top-14 bottom-0 z-40',
            {
              '!left-0': openMenu,
            },
          )}
          docId={docId}
          kind={kind}
          menu={menu}
          user={user}
        />
      )}

      <Main
        className={classnames('flex min-h-main mt-14', {
          'md:ml-52': showNavigation,
          'mx-auto max-w-screen-form': !showNavigation,
        })}
      >
        <div className="w-full bg-background">{children}</div>
      </Main>
    </>
  );
};

export default Layout;
