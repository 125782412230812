import { useSelector } from '@innedit/formidable';
import { ChannelType, DocumentType, UserType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import IconGlobe from '../../../icons/Globe';
import { ReducersProps } from '../../../reducers';
import { NavigationProps } from './index';
import NavGroup from './NavGroup';

const sitewebMenu = ({
  docId,
  id,
  kind,
  user,
}: DocumentType<ChannelType> & {
  docId?: string;
  user?: DocumentType<UserType>;
}) => {
  let menu: { name: string; to: string }[][];

  switch (kind) {
    case 'website':
      menu = [
        [
          {
            name: 'Aperçu',
            to: `/espaces/${docId}/canaux/${id}/apercu/`,
          },
        ],
      ];
      break;

    case 'pos':
    default:
      menu = [
        [
          {
            name: 'Aperçu',
            to: `/espaces/${docId}/canaux/${id}/apercu/`,
          },
        ],
      ];
  }

  if ('admin' === user?.type) {
    menu[0].push({
      name: 'Pages',
      to: `/espaces/${docId}/canaux/${id}/pages/`,
    });
    menu[0].push({
      name: 'Articles',
      to: `/espaces/${docId}/canaux/${id}/articles/`,
    });
    menu[0].push({
      name: 'Bannières',
      to: `/espaces/${docId}/canaux/${id}/banners/`,
    });
    menu[0].push({
      name: 'Mémos',
      to: `/espaces/${docId}/canaux/${id}/memos/`,
    });
    menu[0].push({
      name: 'Paramètres',
      to: `/espaces/${docId}/canaux/${id}/settings/`,
    });
  }

  return menu;
};

const NavigationCanaux: FC<NavigationProps> = ({ className, docId, user }) => {
  const channels: DocumentType<ChannelType>[] | undefined = useSelector(
    (state: ReducersProps) => state.cms.channels,
  );

  if (
    'admin' !== user?.type &&
    (!user || !user.profils || !user.profils.includes('channels'))
  ) {
    return null;
  }

  return (
    <NavGroup
      // addOnClick={handleAddOnClick}
      className={className}
      items={
        channels && channels.length > 0
          ? channels.map(channel => ({
              acl: {
                admin: true,
              },
              iconLeft: IconGlobe,
              id: channel.id,
              menu: sitewebMenu({
                docId,
                user,
                ...channel,
              }),
              name: channel.libelle,
              path: `/espaces/${docId}/canaux/${channel.id}/`,
              profil: 'channels',
              to: `/espaces/${docId}/canaux/${channel.id}/apercu/`,
            }))
          : undefined
      }
      title="Canaux"
      user={user}
    />
  );
};

export default NavigationCanaux;
