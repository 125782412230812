import React, { FC, SyntheticEvent } from 'react';

import IconAdd from '../../../icons/Add';
import Button from '../../Button';

export interface NavItemProps {
  addOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
}
const NavItem: FC<NavItemProps> = ({ addOnClick, children }) => (
  <h2 className="flex items-center justify-between px-4 pb-1">
    <span className="uppercase text-dark-300 font-medium text-xs">
      {children}
    </span>

    {addOnClick && (
      <Button
        iconRight={IconAdd}
        onClick={addOnClick}
        size="sm"
        variant="link"
      />
    )}
  </h2>
);

export default NavItem;
