import { useSelector } from '@innedit/formidable';
import { CollectionType, DocumentType } from '@innedit/innedit-type';
import React, { FC } from 'react';

import IconAdd from '../../../icons/Add';
import IconAddressCard from '../../../icons/AddressCard';
import IconEdit from '../../../icons/Edit';
import IconStream from '../../../icons/Stream';
import { ReducersProps } from '../../../reducers';
import { NavigationProps, SubMenuItemType } from './index';
import NavGroup from './NavGroup';

const collectionsMenu = ({
  collections,
  docId,
}: {
  collections?: DocumentType<CollectionType>[];
  docId: string;
}) => {
  let menu: SubMenuItemType[] = [];
  if (collections) {
    menu = collections?.map(collection => ({
      actionProps: {
        className: 'absolute top-[9px] right-5',
        color: 'tertiary',
        iconLeft: IconEdit,
        size: 'sm',
        to: `/espaces/${docId}/collections/${collection.id}/update/`,
        variant: 'link',
      },
      name: collection.name,
      to: `/espaces/${docId}/collections/${collection.id}/`,
    }));
  }

  return [menu];
};

const NavigationConnaissances: FC<NavigationProps & { docId: string }> = ({
  className,
  docId,
  user,
}) => {
  const collections: DocumentType<CollectionType>[] | undefined = useSelector(
    (state: ReducersProps) => state.cms.collections,
  );

  if (
    'admin' !== user?.type &&
    (!user || !user.profils || !user.profils.includes('knowledge'))
  ) {
    return null;
  }

  return (
    <NavGroup
      className={className}
      items={[
        {
          acl: {
            admin: true,
          },
          iconLeft: IconAddressCard,
          id: 'profiles',
          name: 'Profils',
          profil: 'profiles',
          to: `/espaces/${docId}/profils/`,
        },
        {
          acl: {
            admin: true,
          },
          actionProps: {
            className: 'absolute top-[9px] right-5',
            color: 'neutral',
            iconLeft: IconAdd,
            size: 'sm',
            to: `/espaces/${docId}/collections/create/`,
            variant: 'link',
          },
          iconLeft: IconStream,
          id: 'collections',
          menu: collectionsMenu({ collections, docId }),
          name: 'Collections',
          profil: 'collections',
          to: `/espaces/${docId}/collections/`,
        },
      ]}
      title="Base de connaissances"
      user={user}
    />
  );
};

export default NavigationConnaissances;
