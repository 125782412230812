import React, { FC } from 'react';

import { MenuItemType, NavigationProps } from './index';
import MenuItem from './MenuItem';
import NavItem, { NavItemProps } from './NavItem';

interface NavGroupProps extends NavigationProps, NavItemProps {
  className?: string;
  items?: MenuItemType[];
  title: string;
}

const NavGroup: FC<NavGroupProps> = ({
  addOnClick,
  className,
  items,
  title,
  user,
}) => {
  if (!addOnClick && (!items || 0 === items.length)) {
    return null;
  }

  return (
    <div className={className}>
      <NavItem addOnClick={addOnClick}>{title}</NavItem>
      {items && (
        <ul>
          {items.map(item => (
            <MenuItem key={item.id} item={item} user={user} />
          ))}
        </ul>
      )}
    </div>
  );
};

export default NavGroup;
